










import Vue from "vue";

import AppModule from "#root/store/app";
const LoginPanel = () => import("#root/components/login/LoginPanel.vue");

export default Vue.extend({
  components: { LoginPanel },
  name: "Login",
  mounted() {
    this.$nextTick(() => {
      AppModule.hideAlert();
    });
  },
});
